.experience-container {
    /* background-color: red; */
    width: 80%;
    height: 70%;
    border-radius: 100px;
    margin-left: auto;
    margin-top: 50px;
    overflow: auto;
}

.experience-container::-webkit-scrollbar {
    width: 0.5em;
}

.experience-container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#experience {
    /* background-color: aqua; */
    font-size: 48px;
    margin: 0px 60px 0px 150px;
}

#experience-message {
    /* background-color: purple; */
    font-size: 21px;
    margin: 20px 150px 0px 120px;
    padding-left: 30px;
}

button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
}

.position-title {
    color: black;
    text-decoration: none;
}

.position-title:hover {
    background-color: lightgray;
}

.subtitle {
    font-style: italic;
    color: gray;
}

#experience-message dt {
    position: relative; 
  }
  
#experience-message dt span {
    position: absolute;
    left: 0; 
    top: 50%;
    transform: translate(-135%, -55%); 
    margin-right: 16px;
    cursor: pointer;
  }