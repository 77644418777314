.home-container {
    /* background-color: red; */
    width: 80%;
    height: 70%;
    border-radius: 100px;
    margin-left: auto;
    margin-top: 50px;
    overflow: auto;
    padding: 0px;
}

.home-container::-webkit-scrollbar {
    width: 0.5em;
}

.home-container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#hello {
    /* background-color: aqua; */
    font-size: 48px;
    margin: 0px 60px 20px 150px;
}

#welcome-message {
    /* background-color: purple; */
    font-size: 40px;
    margin: 10px 150px 0px 120px;
    padding-left: 30px;
}

@media (max-width: 768px) {
    .home-container {
        display: none;
    }
}