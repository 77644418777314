.skills-container {
    /* background-color: red; */
    width: 80%;
    height: 70%;
    border-radius: 100px;
    margin-left: auto;
    margin-top: 50px;
    overflow: auto;
}

.skills-container::-webkit-scrollbar {
    width: 0.5em;
}

.skills-container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

#skills {
    /* background-color: aqua; */
    font-size: 48px;
    margin: 0px 60px 0px 150px;
}

#skills-message {
    /* background-color: purple; */
    font-size: 21px;
    margin: 20px 150px 0px 120px;
    padding-left: 30px;
}