.contact-container {
    width: 80%;
    height: 70%;
    margin-left: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
}

#contact {
    /* background-color: aqua; */
    font-size: 48px;
    margin: 0px 60px 0px 150px;
}

#contact-message {
    /* background-color: purple; */
    font-size: 21px;
    margin: 20px 150px 0px 120px;
    padding-left: 30px;
}

.hyperlink {
    text-decoration: none;
    color: black;
}

.hyperlink:hover {
    color: #0072b1;
}

.social-icons, .icon-hover {
    height: 50px;
    margin: 10px;
}

.icon-hover {
    display: none;
}

a:hover .social-icons {
    display: none;
}

a:hover .icon-hover {
    display: inline;
}

iframe {
    margin-left: auto;
    margin-right: 150px;
    width: 40%;
    height: 80%;
    border: solid thick black;
    border-radius: 10px;
    position: absolute;
    transform: translate(120%, 10%);
}