.navbar {
    /* background-color: purple; */
    width: 100%;
    height: 10%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 0px 25px 70px;
    margin-top: 30px;
}

.icon {
    object-fit: cover;
    display: flex;
    justify-content: center;
    /* background-color: green; */
    width: 4em;
}

.nav-circle {
    display: inline-block;
    cursor: pointer;
    font-size: 25em;
    padding: 25px 15px 0px 15px;
    height: 20%;
    object-fit: cover;
}

.nav-icon {
    display: none;
    padding: 5px 10px 0px 10px;
    width: 3em;
    cursor: pointer;
    object-fit: cover;
}

nav:hover .nav-circle {
    display: none;
}

nav:hover .nav-icon {
    display: block;
}

@media (max-width: 768px) {
    .navbar {
        display: none;
    }
}