.App {
  width: 100%;
  height:100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.website-title {
  /* background-color: blue; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 15%;
  width: 80%;
  padding: 0%;
  margin-top: 30px;
}

h1 {
  margin: 0;
  padding: 0px 0px 10px 300px;
  font-family: 'IBM Plex Mono';
  font-size: 64px;
}

.parent-container {
  /* background-color: red; */
  display: flex;
  align-items: center;
  height: 70%;

}

.container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 78%;
  height: 100%;
  margin: auto;
  margin-top: 0px;
  margin-left: 10%;
  border-radius: 100px;
}

.arrow-object {
  /* background-color: aquamarine; */
  width: 7%;
  height: auto;
  margin-right: 60px;
  cursor: pointer;
}

.arrow-object:hover {
  animation: beat 1.5s infinite;
}

@keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.valentina-pic {
  position: absolute;
  height: 110%;
  width: 40%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-127%, -53%);
}

.download-button-default, .download-button-hover {
  position: absolute;
  height: 6%;
  width: 3.5%;
  top: 78%;
  left: 80%;
}

.download-button-hover {
  display: none;
}

a:hover .download-button-default {
  display: none;
}

a:hover .download-button-hover {
  display: inline;
}

.coming-soon {
  display: none;
}

@media (max-width: 768px) {

  .arrow-object {
    display: none;
  }

  .download-component {
    display: none;
  }

  .website-title {
    /* background-color: blue; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 12%;
    width: 100%;
    margin-left: 10%;
  }
  
  h1 {
    margin: 0;
    padding-left: 20%;
    font-family: 'IBM Plex Mono';
    font-size: 42px;
  }

  .valentina-pic {
    position: absolute;
    height: 60%;
    width: 40%;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-127%, -15%);
  }

  .container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .coming-soon {
    display: flex;
    display: contents;
    font-size: 200%;
    text-align: end;
  }

  .coming-soon h2 {
    margin-left: 20%;
    margin-bottom: 20%;
    margin-right: 10%;
  }
}