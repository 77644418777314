#root {
  width: 100%;
  height:100%;
  font-family: 'IBM Plex Mono';
}

html, body {
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  background-color: #ffe571;
  @supports (not (display: flex)) {
    display: block;
    text-align: center;
}
}